import React, { useRef, useState, useEffect } from "react";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import { useDispatch, useSelector } from "react-redux";
import EthCrypto from "eth-crypto";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

export const Header = (props) => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const tokenData = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [whitelisting, setWhitelisting] = useState(false);

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  /*************/
  /* ClaimNFTs */
  /*************/

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
      setFeedback("");
      setClaimingNft(true);
      blockchain.smartContract.methods
        .mint(blockchain.account, _amount)
        .send({
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
          to: "0xF25B2b0a5eDDc42b7b5769dCfEF02c40DaA54C6f", //TODO: change to final contract
          from: blockchain.account,
          value: blockchain.web3.utils.toWei((0.069 * _amount).toString(), "ether"),
        })
        .once("error", (err) => {
          console.log(err);
          setClaimingNft(false);
        })
        .then((receipt) => {
          setFeedback(
            "Successfully minted #BlackPunksMatter NFT"
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
  };

  const [mintAmountState, setMintAmountState] = useState(2);

  const handleIncrement = () => {
    if (mintAmountState + 1 <= 4) {
      setMintAmountState(mintAmountState + 1);
    }
  };

  const handleDecrement = () => {
    if (mintAmountState - 1 >= 1) {
      setMintAmountState(mintAmountState - 1);
    }
  };

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {'#BlackPunksMatter'}
                  <span></span>
                </h1>
                <p>Did you know 90% of the cheapest Crypto Punks are <a href='https://www.larvalabs.com/cryptopunks/attributes' target='_blank'>Black?</a></p>
                <p>Black Punks Matter and we want to change the narrative!</p>
                <p>There are 4200 #BlackPunksMatter NFT available for 0.069Ξ each. No rarity rank, we celebrate equality.</p>
                <p>50% of the proceeds will be donated to <a href='https://blacklivesmatter.com/' target='_blank'>#BlackLivesMatter</a> after soldout.</p>
                <p>Join our cause and support the Black Community!</p>
                {/* Mint Button */}

                {Number(tokenData.totalSupply) > 0 ? (
                  <>
                    <h1>{tokenData.totalSupply}/4200</h1>
                  </>
                ) : (<> </>)}

                {/* {Number(tokenData.totalSupply) === 10000 ? (
                  <>
                    <p>Sale has ended, you can still buy on opensea</p> //TODO add opensea link
                  </>
                ) : ( */}
                  <>
                    {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerMedium />
                        <a
                          className='btn btn-custom btn-lg page-scroll'
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        >
                          CONNECT WALLET
                        </a>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription style={{ textAlign: "center" }}>
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    ) : (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerSmall />

                        <ButtonGroup size="small" aria-label="small outlined button group">
                          <Button style={{ fontSize: '25px', backgroundColor: '#555', color: '#fff' }} onClick={handleDecrement}>-</Button>
                          <Button style={{ fontSize: '25px', backgroundColor: '#555', color: '#fff' }} disabled>{mintAmountState}</Button>
                          <Button style={{ fontSize: '25px', backgroundColor: '#555', color: '#fff'}} onClick={handleIncrement}>+</Button>
                        </ButtonGroup>
                        <s.SpacerSmall />
                        <a
                          className='btn btn-custom btn-lg page-scroll'
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs(mintAmountState);
                            getData();
                          }}
                        >
                          {claimingNft ? "Minting..." : "Mint " + mintAmountState + " #BlackPunksMatter NFT"}
                        </a>
                        <p>{feedback}</p>
                      </s.Container>

                    )}
                  </>
                )}

                {/* Mint button ends */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
