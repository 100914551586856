export const Contact = (props) => {
  return (
    <div>
      <div id='abovefooter'>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            #BlackPunksMatter NFT does not have any association with Crypto Punks or Larvalabs
          </p>
          <p>
            COPYRIGHT © 2022 BLACK PUNKS MATTER NFT - ALL RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </div>
  )
}
