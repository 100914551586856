export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <a className='navbar-brand page-scroll' href='#page-top'>
            “We must accept finite disappointment, but never lose infinite hope.”
          </a>
        </div>
      </div>
    </nav>
  )
}
